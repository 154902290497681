// aip.aegion.com
const URL = 'https://api.aip.aegion.com';

const TAB_PREFIX = 'UAT';
const TAB_SUFFIX = '';

const aipurls = {
	admin: 'https://api.aip.aegion.com/admin',
	account: 'https://api.aip.aegion.com/account',
	assetview: 'https://api.aip.aegion.com/assetview',
	assetviewWS: 'wss://ws.aip.aegion.com/assetview',
	assetviewCorrelator: 'https://api.aip.aegion.com/assetview-correlator',
	customerusers: 'https://api.aip.aegion.com/customer-users',
	cisview: 'https://api.aip.aegion.com/cisview',
	cisviewextended: 'https://api.aip.aegion.com/cisview-extended',
	cisviewreadonly: 'https://api.aip.aegion.com/cisview-readonly',
	cisviewedits: `https://api.aip.aegion.com/cisview-edits`,
	fieldline: 'https://api.aip.aegion.com/fieldline',
	fieldlinebinary: 'https://api.aip.aegion.com/fieldline-binary',
	chargeline: 'https://api.aip.aegion.com/chargeline',
	chargelinebinary: 'https://api.aip.aegion.com/chargeline-binary',
	maps: 'https://api.aip.aegion.com/maps',
	scanline: 'https://api.aip.aegion.com/scanline',
	scanlineWorkflow: 'https://api.aip.aegion.com/scanline-workflow',
	scanlineReport: 'https://api.aip.aegion.com/scanlineReport',
	scanlineReportV2: 'https://api.aip.aegion.com/scanline-report-v2',
	scanlineReportV2WS: 'wss://ws.aip.aegion.com/scanline-report-v2',
	scanlineTags: 'https://api.aip.aegion.com/scanline-tags',
	templateViewer: 'https://api.aip.aegion.com/internal-template-viewer-2',
	templates: 'https://api.aip.aegion.com/templates',
	liveline: 'https://api.aip.aegion.com/liveline',
	settings: 'https://api.aip.aegion.com/settings',
	uploaderbucket: 'aegion-aip-prod',
	uploderbucketbasepath: 'https://api.aip.aegion.com/uploader',
	uploaderwebsocket: 'wss://ws.aip.aegion.com/uploader',
	uploaderbucketaccessurl: 'https://api.aip.aegion.com/uploader/access',
	uploaderfilesubmiturl:
		'https://api.aip.aegion.com/cisview-extended/postUploaderSubmit',
	parseFile: 'https://api.aip.aegion.com/parse-file',
	parseFileWS: 'wss://ws.aip.aegion.com/parse-file',
	mlServerApi: 'https://ml.aip.aegion.com/',
	mlServerLambdaApi: 'https://api.aip.aegion.com/ml/',
	downloadReport: 'https://api.aip.aegion.com/dataprocessor-report',
	notifications: 'https://api.aip.aegion.com/aip-notifications',
	viewAs: 'https://api.aip.aegion.com/view-as',
	poolid: 'cognito-idp.us-west-2.amazonaws.com/us-west-2_8bd8llCAP',
	identifypoolid: 'us-west-2:58ed4f86-2329-4853-9a36-057f874bc5d9',
	clientid: '75b6s763dilenknkm6mb38va7f',
	bucket: 'aegion-aip-prod-nonversioned',
	versionedBucket: 'aegion-aip-prod',
	dataIntegrity: 'https://api.aip.aegion.com/dataIntegrity'
};

export default aipurls;

export { URL, TAB_PREFIX, TAB_SUFFIX };
